import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {motion} from 'framer-motion/dist/framer-motion'
import AnimationSettings from "../../common/AnimationSettings";

export const LegalNotice = () => {
    return (
        <motion.div className="route-container" key="5" {...AnimationSettings}>
            <Container className="Cookie-Policy mx-auto pb-5">
                <Row className="mb-2 mt-3">
                    <Col lg="8">
                        <h1 className="display-4 mb-4">Legal notice</h1>
                        <hr className="t_border my-4 ml-0 text-left"/>
                    </Col>
                </Row>

                <Row className="mb-5 mt-3">
                    <Col>
                        <h2 className="mt-3">Online Dispute Resolution website of the EU Commission</h2>
                        <div>
                            In order for consumers and traders to resolve a dispute out-of-court, the European
                            Commission
                            developed the Online Dispute Resolution Website: www.ec.europa.eu/consumers/odr
                            <br/>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-5 mt-3">
                    <Col>
                        <h2 className="mt-3">Data protection</h2>
                        <div>
                            In general, when visiting the website of Maximilian Thomas, no personal data are saved.
                            However,
                            these data can be given on a voluntary basis. No data will be passed on to third parties
                            without
                            your consent. We point out that in regard to unsecured data transmission in the internet
                            (e.g.
                            via email), security cannot be guaranteed. Such data could possibIy be accessed by third
                            parties.
                            <br/>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-5 mt-3">
                    <Col>
                        <h2 className="mt-3">Google Analytics</h2>
                        <div>
                            This website uses Google Analytics, a web analytics service provided by Google, Inc.
                            (»Google«).
                            Google Analytics uses cookies, which are text files placed on your computer, to help the
                            website
                            analyze how users use the site. The information generated by the cookie about your use of
                            the
                            website (including your IP address) will be transmitted to and stored by Google on servers
                            in
                            the United States. In case of activation of the IP anonymization, Google will
                            truncate/anonymize
                            the last octet of the IP address for Member States of the European Union as well as for
                            other
                            parties to the Agreement on the European Economic Area. Only in exceptional cases, the full
                            IP
                            address is sent to and shortened by Google servers in the USA. On behalf of the website
                            provider
                            Google will use this information for the purpose of evaluating your use of the website,
                            compiling reports on website activity for website operators and providing other services
                            relating to website activity and internet usage to the website provider. Google will not
                            associate your IP address with any other data held by Google. By using this website, you
                            consent
                            to the processing of data about you by Google in the manner and for the purposes set out
                            above.
                            <br/>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-5 mt-3">
                    <Col>
                        <h2 className="mt-3">How to prevent being tracked by Google Analytics</h2>
                        <div>
                            You may refuse the use of cookies by selecting the appropriate settings on your browser.
                            However, please note that if you do this, you may not be able to use the full functionality
                            of
                            this website.
                            Furthermore you can prevent Google’s collection and use of data (cookies and IP address) by
                            downloading and installing the browser plug-in available under
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
                            <br/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </motion.div>
    );
}
